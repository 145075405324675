import { LineChart } from "@mui/x-charts/LineChart";
import SingleSelect from "./SingleSelect";
import { useState } from "react";

export default function LineGraph(props) {
  return (
    <>
      <div className="lineGraphSelect"></div>
      <SingleSelect
        filterOptions={props.filterOptions}
        setFilterOptions={props.setFilterOptions}
        options={props.options}
        labelId={props.labelId}
        compId={props.compId}
        compLabel={props.compLabel}
        compName={props.compName}
      />
      <LineChart
        height={300}
        series={[
          { data: props.specificData, label: props.specificDataLabel },
          { data: props.baselineData, label: props.baselineDataLabel },
        ]}
        xAxis={[{ scaleType: "point", data: props.xLabels }]}
        yAxis={[{ min: 1, max: 4 }]}
      />
    </>
  );
}
