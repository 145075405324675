import Pagination from "@mui/material/Pagination";
import Grid from "@mui/material/Unstable_Grid2";
import Usercard from "./Usercard";
import { useEffect, useState } from "react";
export default function MaingridRight(props) {
  const [paginationCount, setPaginationCount] = useState(1);

  const dataset = props.dataset;

  useEffect(() => {
    setPaginationCount(Math.ceil(dataset.length / 12));
  }, [props.dataset]);

  const allStudents = dataset.length ? (
    dataset
      .slice((props.currentPage - 1) * 12, (props.currentPage - 1) * 12 + 12)
      .map((student, index) => {
        return (
          <Grid xs={1} key={student + index}>
            <Usercard
              key={student[1]}
              studentName={student[4]}
              studentID={student[1]}
              studentClass={student[5]}
              hebrewName={student[6]}
              refreshToken={props.refreshToken}
              imageDriveUrl={student[student.length - 3]}
              setHelpOpen={props.setHelpOpen}
              newStudent={props.newStudent}
              setNewImageBlob={props.setNewImageBlob}
              imageBlobs={props.imageBlobs}
              isNewToBialik={student[41] != "" && !student[5].includes("JK")}
              getAppCheckToken={props.getAppCheckToken}
              selectedTerm={props.filterOptions["Term"]}
              photoReleaseAllowed={student[55] == "No"}
              birthdate={student[56]}
              jsAvg={
                (props.filterOptions["Term"] == "GS Average" &&
                  (((Number(student[16]) || 0) + (Number(student[18]) || 0)) /
                    ((student[16] && 1) + (student[18] && 1)) ||
                    false)) ||
                (props.filterOptions["Term"] == "JS Average" &&
                  (((Number(student[17]) || 0) + (Number(student[19]) || 0)) /
                    ((student[17] && 1) + (student[19] && 1)) ||
                    false)) ||
                Number(
                  props.termMarksAvg(student)[
                    props
                      .termMarksAvg(student)
                      .indexOf(props.filterOptions["Term"]) + 1
                  ]
                ) ||
                false
              }
              tags={[
                ["IEP", student[22] != ""],
                ["GP", student[23] != ""],
                ["SSF", student[25] != ""],
                [
                  "Shiluv",
                  student[29].includes(
                    `${new Date().getFullYear() % 100}/${
                      (new Date().getFullYear() + 1) % 100
                    }`
                  ),
                ],
                [
                  "Ulpan",
                  student[28].includes(
                    `${new Date().getFullYear() % 100}/${
                      (new Date().getFullYear() + 1) % 100
                    }`
                  ),
                ],
                ["Anaphylaxis", student[46] == "Yes"],
                ["Asthma", student[48] == "Yes"],
                ["Serious Condition", student[50] == "Yes"],
                ["Ivrit MOD", student[57].includes("MOD")],
                ["Tanach MOD", student[58].includes("MOD")],
                ["ELL", student[59] != ""],
                ["Kippah Yes", student[60] == "Wear"],
                ["Kippah No", student[60] == "Not wear"],
                ["Kippah Student", student[60] == "Up to child"],
              ]}
            />
          </Grid>
        );
      })
  ) : (
    <Grid xs={4}>
      <h3 className="centerPagination"> {props.loaded && "No Results"} </h3>
    </Grid>
  );

  const pagiChange = function (newPage) {
    props.setCurrentPage(newPage);
  };

  return (
    <Grid container spacing={2} columns={{ xl: 4, lg: 3, xs: 1, sm: 2, md: 2 }}>
      {allStudents}

      {dataset.length && (
        <Grid xs={4}>
          <div className="centerPagination">
            <Pagination
              onChange={(event, page) => {
                pagiChange(page);
                console.log(`changed to ${page}`);
              }}
              count={paginationCount}
              color="primary"
              page={props.currentPage}
            />
          </div>
        </Grid>
      )}
    </Grid>
  );
}
