import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import LineGraph from "./lineGraph";
import { useState } from "react";
import { ContinuousColorLegend } from "@mui/x-charts";

const average = function (array) {
  if (array.length === 0) return 0; // Handle empty array case
  let sum = 0;
  let count = 0;
  for (let i = 0; i < array.length; i++) {
    if (typeof array[i] === "number" && !isNaN(array[i]) && array[i] !== 0) {
      sum += array[i];
      count++;
    }
  }
  return count === 0 ? 0 : (sum / count).toFixed(2); // Handle case where no valid numbers are found
};

export default function DataTable(props) {
  const [filterOptions, setFilterOptions] = useState({
    termSelect: "GS Average",
  });

  function changeFilterOptions(event) {
    setFilterOptions({
      ...filterOptions,
      [event.target.name]: event.target.value,
    });
  }

  let rowData = [];

  let headers = [];

  function getAverage(array) {
    let sum = 0;
    for (let i = 0; i < array.length; i++) {
      sum += array[i];
    }
    return sum / array.length;
  }

  let termMarksAvg = props.termMarksAvg;

  for (const i in props.dataPacket) {
    if (props.indivMarks && headers.length == 0) {
      headers = termMarksAvg.filter((x) => isNaN(x));
    } else if (!props.indivMarks) {
      headers.push(i);
    }

    if (props.indivMarks) {
      rowData = termMarksAvg.filter((x) => !isNaN(x));
    } else {
      rowData.push(
        props.arrayFlipped
          ? props.studentInfo[props.studentInfo.length - props.dataPacket[i]]
          : props.studentInfo[props.dataPacket[i]]
      );
    }
  }

  const createHeader = headers.map((headerName, index) => {
    return (
      !props.hideHeader &&
      (rowData[index] || props.indivMarks) && (
        <TableCell align="center" key={headerName + index}>
          {headerName}
        </TableCell>
      )
    );
  });

  const createRowData = rowData.map((row, index) => {
    if (!row) return null;

    let parts;

    if (props.formatAsButton) {
      return (
        <TableCell align="center" key={index}>
          <Button variant="outlined" onClick={() => window.open(row)}>
            Access Here
          </Button>
        </TableCell>
      );
    } else if (props.insertBr) {
      parts = row.split("•").map((part, index, array) => (
        <React.Fragment key={index}>
          {index == 0 &&
            props.studentInfo[54] == "Yes" &&
            props.dataTitle == "Getting to Know You Data" && (
              <>
                <strong>Updated This Year</strong>
                <br />
                <br />
              </>
            )}
          {part}
          {index < array.length - 1 && (
            <>
              {index >= 1 && (
                <>
                  <br />
                  <br />
                </>
              )}
              •
            </>
          )}
        </React.Fragment>
      ));
    } else if (props.isGraph) {
      let loadedMarks = [];
      let averageMarks = [];

      if (props.studentInfo[16] != "" || props.studentInfo[18] != "") {
        loadedMarks.push({
          "GS Average": [
            (!isNaN(props.studentInfo[61]) && props.studentInfo[61]) || null,
            (!isNaN(props.studentInfo[63]) && props.studentInfo[63]) || null,
            (!isNaN(props.studentInfo[16]) && props.studentInfo[16]) || null,
            (!isNaN(props.studentInfo[18]) && props.studentInfo[18]) || null,
          ],
        });

        let oldPendAvgs1 = [];
        let oldPendAvgs2 = [];
        let pendAvgs1 = [];
        let pendAvgs2 = [];

        props.rawData.forEach((student) => {
          if (student[5] == props.studentInfo[5]) {
            !isNaN(student[61]) && oldPendAvgs1.push(Number(student[61]));
            !isNaN(student[63]) && oldPendAvgs2.push(Number(student[63]));
            !isNaN(student[16]) && pendAvgs1.push(Number(student[16]));
            !isNaN(student[18]) && pendAvgs2.push(Number(student[18]));
          }
        });

        averageMarks.push({
          "GS Average": [
            average(oldPendAvgs1) || null,
            average(oldPendAvgs2) || null,
            average(pendAvgs1) || null,
            average(pendAvgs2) || null,
          ],
        });
      }

      if (props.studentInfo[17] != "" || props.studentInfo[19] != "") {
        loadedMarks.push({
          "JS Average": [
            (!isNaN(props.studentInfo[62]) && props.studentInfo[62]) || null,
            (!isNaN(props.studentInfo[64]) && props.studentInfo[64]) || null,
            (!isNaN(props.studentInfo[17]) && props.studentInfo[17]) || null,
            (!isNaN(props.studentInfo[19]) && props.studentInfo[19]) || null,
          ],
        });

        let oldPendAvgs1 = [];
        let oldPendAvgs2 = [];
        let pendAvgs1 = [];
        let pendAvgs2 = [];

        props.rawData.forEach((student) => {
          if (student[5] == props.studentInfo[5]) {
            !isNaN(student[62]) && oldPendAvgs1.push(Number(student[62]));
            !isNaN(student[64]) && oldPendAvgs2.push(Number(student[64]));
            !isNaN(student[17]) && pendAvgs1.push(Number(student[17]));
            !isNaN(student[19]) && pendAvgs2.push(Number(student[19]));
          }
        });
        averageMarks.push({
          "JS Average": [
            average(oldPendAvgs1) || null,
            average(oldPendAvgs2) || null,
            average(pendAvgs1) || null,
            average(pendAvgs2) || null,
          ],
        });
      }

      props.term1Marks.forEach((courseMark, index) => {
        let oldPendAvgs1 = [];
        let oldPendAvgs2 = [];
        let pendAvgs1 = [];
        let pendAvgs2 = [];

        if (index % 2 == 0) {
          courseMark &&
            loadedMarks.push({
              [courseMark]: [
                props.oldTerm1Marks.indexOf(courseMark) != -1
                  ? props.oldTerm1Marks[
                      props.oldTerm1Marks.indexOf(courseMark) + 1
                    ]
                  : null,
                props.oldTerm2Marks.indexOf(courseMark) != -1
                  ? props.oldTerm2Marks[
                      props.oldTerm2Marks.indexOf(courseMark) + 1
                    ]
                  : null,
                props.term1Marks[index + 1],
                props.term2Marks.indexOf(courseMark) != -1
                  ? props.term2Marks[props.term2Marks.indexOf(courseMark) + 1]
                  : null,
              ],
            });

          props.rawData.forEach((student) => {
            if (student[5] == props.studentInfo[5]) {
              if (student[42].includes(courseMark)) {
                let dataArray = student[42]
                  .replaceAll("•", "")
                  .replaceAll(" ", "")
                  .replaceAll("SocialStudies", "Social Studies")
                  .split(/:|\n/);

                pendAvgs1.push(
                  Number(dataArray[dataArray.indexOf(courseMark) + 1])
                );
              }

              if (student[43].includes(courseMark)) {
                let dataArray = student[43]
                  .replaceAll("•", "")
                  .replaceAll(" ", "")
                  .replaceAll("SocialStudies", "Social Studies")
                  .split(/:|\n/);

                pendAvgs2.push(
                  Number(dataArray[dataArray.indexOf(courseMark) + 1])
                );
              }

              if (student[65].includes(courseMark)) {
                let dataArray = student[65]
                  .replaceAll("•", "")
                  .replaceAll(" ", "")
                  .replaceAll("SocialStudies", "Social Studies")
                  .split(/:|\n/);

                oldPendAvgs1.push(
                  Number(dataArray[dataArray.indexOf(courseMark) + 1])
                );
              }

              if (student[66].includes(courseMark)) {
                let dataArray = student[66]
                  .replaceAll("•", "")
                  .replaceAll(" ", "")
                  .replaceAll("SocialStudies", "Social Studies")
                  .split(/:|\n/);

                oldPendAvgs2.push(
                  Number(dataArray[dataArray.indexOf(courseMark) + 1])
                );
              }
            }
          });
          averageMarks.push({
            [courseMark]: [
              average(oldPendAvgs1) || null,
              average(oldPendAvgs2) || null,
              average(pendAvgs1) || null,
              average(pendAvgs2) || null,
            ],
          });
        }
      });

      props.term2Marks.forEach((courseMark, index) => {
        let oldPendAvgs1 = [];
        let oldPendAvgs2 = [];
        let pendAvgs1 = [];
        let pendAvgs2 = [];
        if (index % 2 == 0 && !props.term1Marks.includes(courseMark)) {
          loadedMarks.push({
            [courseMark]: [null, props.term2Marks[index + 1]],
          });

          props.rawData.forEach((student) => {
            if (student[5] == props.studentInfo[5]) {
              if (student[42].includes(courseMark)) {
                let dataArray = student[42]
                  .replaceAll("•", "")
                  .replaceAll(" ", "")
                  .replaceAll("SocialStudies", "Social Studies")
                  .split(/:|\n/);

                pendAvgs1.push(
                  Number(dataArray[dataArray.indexOf(courseMark) + 1])
                );
              }

              if (student[43].includes(courseMark)) {
                let dataArray = student[43]
                  .replaceAll("•", "")
                  .replaceAll(" ", "")
                  .replaceAll("SocialStudies", "Social Studies")
                  .split(/:|\n/);

                pendAvgs2.push(
                  Number(dataArray[dataArray.indexOf(courseMark) + 1])
                );
              }

              if (student[65].includes(courseMark)) {
                let dataArray = student[65]
                  .replaceAll("•", "")
                  .replaceAll(" ", "")
                  .replaceAll("SocialStudies", "Social Studies")
                  .split(/:|\n/);

                oldPendAvgs1.push(
                  Number(dataArray[dataArray.indexOf(courseMark) + 1])
                );
              }

              if (student[66].includes(courseMark)) {
                let dataArray = student[66]
                  .replaceAll("•", "")
                  .replaceAll(" ", "")
                  .replaceAll("SocialStudies", "Social Studies")
                  .split(/:|\n/);

                oldPendAvgs2.push(
                  Number(dataArray[dataArray.indexOf(courseMark) + 1])
                );
              }
            }
          });
          averageMarks.push({
            [courseMark]: [
              average(oldPendAvgs1) || null,
              average(oldPendAvgs2) || null,
              average(pendAvgs1) || null,
              average(pendAvgs2) || null,
            ],
          });
        }
      });

      let selOptions = loadedMarks.map((course) => {
        return Object.keys(course)[0];
      });

      let selectedIndex;

      for (let i in loadedMarks) {
        if (Object.keys(loadedMarks[i])[0] == filterOptions.termSelect) {
          selectedIndex = i;
          break;
        }
      }

      let specificData = [
        Object.values(loadedMarks[selectedIndex])[0][0],
        Object.values(loadedMarks[selectedIndex])[0][1],
        Object.values(loadedMarks[selectedIndex])[0][2],
        Object.values(loadedMarks[selectedIndex])[0][3],
      ];

      let baselineData = [
        Object.values(averageMarks[selectedIndex])[0][0],
        Object.values(averageMarks[selectedIndex])[0][1],
        Object.values(averageMarks[selectedIndex])[0][2],
        Object.values(averageMarks[selectedIndex])[0][3],
      ];

      return (
        <LineGraph
          options={selOptions}
          specificData={specificData}
          baselineData={baselineData}
          specificDataLabel={props.studentInfo[4]}
          baselineDataLabel={`${props.studentInfo[5]} Average (My Students)`}
          filterOptions={filterOptions}
          setFilterOptions={changeFilterOptions}
          labelId="termSelectLabel"
          compId="termSelectId"
          compLabel="Subject"
          compName="termSelect"
          xLabels={[
            "Term 1 (2022-23)",
            "Term 2 (2022-23)",
            "Term 1 (2023-24)",
            "Term 2 (2023-24)",
          ]}
        />
      );
    } else {
      parts = row;
    }

    return (
      <TableCell align={props.insertBr ? "left" : "center"}>
        <p>{parts}</p>
      </TableCell>
    );
  });

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>{createHeader}</TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            {createRowData}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
