import "./App.css";
import Navbar from "./components/Navbar";
import LogoutMessage from "./components/LogoutMessage";
import Maingrid from "./components/Maingrid";
import Container from "@mui/material/Container";
import HelpDialog from "./components/HelpDialog";
import { useCallback, useEffect, useState } from "react";

// Import the functions you need from the SDKs you need
import { initializeApp } from "https://www.gstatic.com/firebasejs/10.12.2/firebase-app.js";
import { getAnalytics } from "https://www.gstatic.com/firebasejs/10.12.2/firebase-analytics.js";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  signOut,
} from "https://www.gstatic.com/firebasejs/10.12.2/firebase-auth.js";
import {
  initializeAppCheck,
  ReCaptchaV3Provider,
  getToken,
} from "https://www.gstatic.com/firebasejs/10.12.2/firebase-app-check.js";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDcdI5txUldaE7jEsp4oZ87o5CZcncfJ0c",
  authDomain: "teacher-dashboard-427618.firebaseapp.com",
  projectId: "teacher-dashboard-427618",
  storageBucket: "teacher-dashboard-427618.appspot.com",
  messagingSenderId: "16554155316",
  appId: "1:16554155316:web:8edbb983275117aaa61961",
  measurementId: "G-4Z0PW07M49",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const provider = new GoogleAuthProvider();

const auth = getAuth();

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LeWwwcqAAAAAHaCuT_Kt9wvorHhd-hzDNMpF0Vg"),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
});
let userObj;

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [helpOpen, setHelpOpen] = useState([false, "help"]);

  const login = useCallback(() => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  }, []);

  const logout = useCallback(() => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        const uid = user.uid;

        setLoggedIn(true);
        userObj = user;

        // ...
      } else {
        // User is signed out
        // ...

        setLoggedIn(false);

        console.log("logged out");
      }
    });
  }, []);

  const getNewestToken = useCallback(() => {
    return new Promise((resolve, reject) => {
      userObj
        .getIdToken()
        .then((token) => {
          resolve(token);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }, []);

  const getNewestAppCheckToken = useCallback(() => {
    return new Promise((resolve, reject) => {
      getToken(appCheck, false)
        .then((token) => {
          resolve(token);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }, []);

  return (
    <>
      <HelpDialog
        setHelpOpen={setHelpOpen}
        helpOpen={helpOpen}
        getAppCheckToken={getNewestAppCheckToken}
        refreshToken={getNewestToken}
      />
      <Navbar setHelpOpen={setHelpOpen} />
      <Container className="mainContainer" maxWidth="xl">
        {loggedIn ? (
          <Maingrid
            logoutFn={logout}
            getAppCheckToken={getNewestAppCheckToken}
            refreshToken={getNewestToken}
            setHelpOpen={setHelpOpen}
          />
        ) : (
          <LogoutMessage loginFn={login} />
        )}
      </Container>
    </>
  );
}

export default App;
