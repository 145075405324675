import { Typography } from "@mui/material";
import DataTable from "./DataTable";
import { useState, useEffect } from "react";

const masterStructure = [
  {
    "Digital Records": {
      "Report Cards Folder": 2,
      "DSRB Folder": 1,
      "PTCL File": 4,
    },
    options: { isReverse: true, isLink: true },
  },
  {
    "Getting to Know You Data": { "Current Year": 33 },
    options: { insertBr: true, hideHeader: true },
  },
  {
    "Resource Links": {
      "IEP File": 5,
      "Growth Plan": 6,
      "SSF File": 7,
    },
    options: { isReverse: true, isLink: true },
  },
  { "Report Cards": { "T1 GS": 16, "T2 GS": 18, "T1 JS": 17, "T2 JS": 19 } },
  {
    "Average Marks by Class": { term1: 42, term2: 43 },
    options: { indivMarks: true },
  },
  {
    "Report Graph": { "Report Graph": 0 },
    options: { isGraph: true, hideHeader: true },
  },

  { "EQAO Scores": { Reading: 13, Writing: 14, Math: 15 } },
  {
    "Hebrew Levels": {
      "Hebrew Reading": 7,
      "Hebrew Writing": 8,
      "Hebrew Listening": 9,
      "Hebrew Speaking": 10,
    },
  },
  { Acadience: { Middle: 11, End: 12 } },
  {
    "Admissions Notes": {
      "VP Intake Notes": 35,
      "Hebrew Intake Notes": 36,
      "Admissions Screen Notes": 37,
    },
  },
  {
    "Report Cards (2022-23)": {
      "T1 GS": 61,
      "T2 GS": 63,
      "T1 JS": 62,
      "T2 JS": 64,
    },
  },
  {
    "Average Marks by Class (2022-23)": { term1: 65, term2: 66 },
    options: { indivMarks: true, oldMarks: true },
  },

  {
    Health: {
      Anaphylaxis: 46,
      "Anaphylaxis Comment": 47,
      Asthma: 48,
      "Asthma Comment": 49,
      "Serious Condition": 50,
      "Serious Condition Comment": 51,
      "Allow Acetaminophen/Tylenol": 52,
      "Acetaminophen/Tylenol Comment": 53,
    },
  },

  {
    "Resource Information": {
      Ulpan: 28,
      Shiluv: 29,
      "Ivrit MOD": 57,
      "Tanach MOD": 58,
      ELL: 59,
      "Behavioural Data": 31,
    },
  },

  {
    "Additional Information": {
      Birthdate: 56,

      "Medical Information": 32,
      "Kippah Preference": 60,
      "Photo Release": 55,
    },
  },
];
const lengthOfAddedUrls = 7; //change this when you add more to the end of the response

export default function DataDisplay(props) {
  const dataSelection = props.dataSelection;

  const [term1Marks, setTerm1Marks] = useState([]);
  const [term2Marks, setTerm2Marks] = useState([]);
  const [termMarksAvg, setTermMarksAvg] = useState([]);

  const [oldTerm1Marks, setOldTerm1Marks] = useState([]);
  const [oldTerm2Marks, setOldTerm2Marks] = useState([]);
  const [oldTermMarksAvg, setOldTermMarksAvg] = useState([]);

  useEffect(() => {
    setTerm1Marks(
      dataSelection[42]
        .replaceAll("•", "")
        .replaceAll(" ", "")
        .replaceAll("SocialStudies", "Social Studies")
        .split(/:|\n/)
    );

    if (dataSelection[43] != "") {
      setTerm2Marks(
        dataSelection[43]
          .replaceAll("•", "")
          .replaceAll(" ", "")
          .replaceAll("SocialStudies", "Social Studies")
          .split(/:|\n/)
      );
    }
    if (dataSelection[43] == "" && dataSelection[42] != "") {
      setTerm2Marks(term1Marks);
    } else if (dataSelection[43] != "" && dataSelection[42] == "") {
      setTerm1Marks(term2Marks);
    }
  }, [dataSelection]);

  useEffect(() => {
    setOldTerm1Marks(
      dataSelection[65]
        .replaceAll("•", "")
        .replaceAll(" ", "")
        .replaceAll("SocialStudies", "Social Studies")
        .split(/:|\n/)
    );

    if (dataSelection[66] != "") {
      setOldTerm2Marks(
        dataSelection[66]
          .replaceAll("•", "")
          .replaceAll(" ", "")
          .replaceAll("SocialStudies", "Social Studies")
          .split(/:|\n/)
      );
    }
    if (dataSelection[66] == "" && dataSelection[65] != "") {
      setOldTerm2Marks(term1Marks);
    } else if (dataSelection[66] != "" && dataSelection[65] == "") {
      setOldTerm1Marks(term2Marks);
    }
  }, [dataSelection]);

  useEffect(() => {
    const averagedMark = term1Marks.map((mark, index) => {
      if (index % 2 != 0 && term2Marks.indexOf(term1Marks[index - 1]) != -1) {
        return parseFloat(
          (Number(term1Marks[index]) +
            Number(term2Marks[term2Marks.indexOf(term1Marks[index - 1]) + 1])) /
            2
        ).toFixed(2);
      } else {
        return mark;
      }
    });

    setTermMarksAvg(averagedMark);
  }, [term1Marks, term2Marks]);

  useEffect(() => {
    const averagedMark = oldTerm1Marks.map((mark, index) => {
      if (
        index % 2 != 0 &&
        oldTerm2Marks.indexOf(oldTerm1Marks[index - 1]) != -1
      ) {
        return parseFloat(
          (Number(oldTerm1Marks[index]) +
            Number(
              oldTerm2Marks[oldTerm2Marks.indexOf(oldTerm1Marks[index - 1]) + 1]
            )) /
            2
        ).toFixed(2);
      } else {
        return mark;
      }
    });

    setOldTermMarksAvg(averagedMark);
  }, [oldTerm1Marks, oldTerm2Marks]);

  const formattedStudentData = masterStructure.map((dataCategory, index) => {
    let dataFilled = false;
    let arrayFlipped = false;
    let formatAsButton = false;
    let insertBr = false;
    let formatGraph = false;
    let hideHeader = false;
    let indivMarks = false;
    let oldMarks = false;

    let dataObject = dataCategory[Object.keys(dataCategory)[0]];

    for (const y in dataObject) {
      if (dataCategory["options"]) {
        if (
          dataCategory["options"]["isReverse"] &&
          dataSelection[dataSelection.length - dataObject[y]]
        ) {
          dataFilled = true;
        } else if (
          dataSelection[dataObject[y]] &&
          dataSelection.length - lengthOfAddedUrls > dataObject[y] &&
          !dataCategory["options"]["isReverse"] &&
          !dataCategory["options"]["isGraph"]
        ) {
          dataFilled = true;
        } else if (
          dataCategory["options"]["isGraph"] &&
          ((dataSelection[16] && dataSelection[17]) ||
            (dataSelection[18] && dataSelection[19]))
        ) {
          dataFilled = true;
        }
        insertBr = dataCategory["options"]["insertBr"];
        arrayFlipped = dataCategory["options"]["isReverse"];
        formatAsButton = dataCategory["options"]["isLink"];
        formatGraph = dataCategory["options"]["isGraph"];
        hideHeader = dataCategory["options"]["hideHeader"];
        indivMarks = dataCategory["options"]["indivMarks"];
        oldMarks = dataCategory["options"]["oldMarks"];
      } else if (
        dataSelection[dataObject[y]] &&
        dataSelection.length - lengthOfAddedUrls > dataObject[y]
      ) {
        //If the student data includes the required index and the index is within the columns in the original
        dataFilled = true;
      }
    }

    return (
      dataFilled && (
        <div key={Object.keys(dataCategory)[0] + index}>
          <Typography variant="h6">{Object.keys(dataCategory)[0]}</Typography>
          <DataTable
            arrayFlipped={arrayFlipped}
            formatAsButton={formatAsButton}
            insertBr={insertBr}
            indivMarks={indivMarks}
            oldMarks={oldMarks}
            isGraph={formatGraph}
            term1Marks={term1Marks}
            term2Marks={term2Marks}
            oldTerm1Marks={oldTerm1Marks}
            oldTerm2Marks={oldTerm2Marks}
            termMarksAvg={oldMarks ? oldTermMarksAvg : termMarksAvg}
            dataPacket={dataObject}
            dataTitle={Object.keys(dataCategory)[0]}
            studentInfo={dataSelection}
            rawData={props.rawData}
            hideHeader={hideHeader}
          />
          <br />
        </div>
      )
    );
  });

  return (
    <div className="studentInfoContainer">
      <Typography variant="h4" className="studentInfoText">
        Student Information
      </Typography>
      <br />
      {formattedStudentData}
    </div>
  );
}
