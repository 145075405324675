import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

function valuetext(value) {
  return value;
}

export default function RangeSlider(props) {
  const handleChange = (event) => {
    props.setFilterOptions(event);
  };

  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },

    {
      value: 4,
      label: "4",
    },
  ];

  return (
    <Box
      sx={{
        width: "90%",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "6px",
      }}
    >
      <Slider
        disableSwap
        getAriaLabel={() => "Grade Range"}
        name="gradeRange"
        value={props.filterOptions["gradeRange"]}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        marks={marks}
        min={1}
        max={4}
        step={0.1}
      />
    </Box>
  );
}
