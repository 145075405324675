import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function BasicSelect(props) {
  let selVal = props.filterOptions[props.compName];

  const handleChange = (event) => {
    props.setFilterOptions(event);
    console.log(event.target.name);
  };

  const selectOptions = props.options.map((option, index) => {
    return (
      <MenuItem value={option} key={option + index}>
        {option}
      </MenuItem>
    );
  });

  return (
    <Box sx={{ width: "100%" }}>
      <FormControl fullWidth>
        <InputLabel id={props.compLabel}>{props.compLabel}</InputLabel>
        <Select
          labelId={props.labelId}
          id={props.compId}
          value={selVal}
          label={props.compLabel}
          name={props.compName}
          onChange={handleChange}
        >
          {selectOptions}
        </Select>
      </FormControl>
    </Box>
  );
}
