import "../App.css";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import blogo from "./blogo.png";

export default function Navbar(props) {
  return (
    <Box className="mainNavBox" sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img src={blogo} height="30" className="navImg" /> Bialik Student
            Profiles
          </Typography>
          <IconButton
            aria-label="Help"
            color="inherit"
            onClick={() => props.setHelpOpen([true, "help"])}
          >
            {" "}
            <QuestionMarkIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
