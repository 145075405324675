import { Typography } from '@mui/material';
import '../App.css';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import GoogleIcon from '@mui/icons-material/Google';


export default function Hero(props) {
    

    


    return (
        <Paper elevation={3} className="welcomeBox">
            <Typography variant='h4' gutterBottom>Welcome!</Typography>
            <Typography className="mainSubtitle" variant='subtitle1' gutterBottom>Sign in with your Bialik Google account to get started.</Typography>
            <Button variant='contained' color='primary' onClick={props.loginFn}><GoogleIcon className="googleLoginIcon" /> Sign In with Google</Button>
        </Paper>
    )
}

