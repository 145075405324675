import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function CustomizedDialogs(props) {
  const open = props.helpOpen[0];
  const setOpen = props.setHelpOpen;

  const [classListButtons, setClassListButtons] = useState([]);

  const handleClose = () => {
    setClassListButtons([]);

    setOpen(false);
  };

  useEffect(() => {
    if (props.helpOpen[1] == "opts") {
      const fetchData = async () => {
        let attempts = 0;
        while (attempts < 4) {
          try {
            let newToken = await props.refreshToken();
            let appCheckToken = await props.getAppCheckToken();

            const response = await fetch(
              "https://teacher-dashboard-427618.nn.r.appspot.com/getClassLists",
              {
                method: "GET",
                headers: {
                  "X-Authorization-Token": newToken,
                  "X-Firebase-AppCheck": appCheckToken.token,
                  "Selected-Class": props.helpOpen[2],
                },
              }
            );

            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            const classButtonMap = data[1].map((link, index) => {
              return (
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ width: "100%" }}
                  onClick={() => window.open(link)}
                  className="dialogActionButtons"
                >
                  {data[0][index + 1]}
                </Button>
              );
            });
            setClassListButtons(classButtonMap);
            break;
          } catch (error) {
            console.error("Attempt to fetch data failed:", error);
            attempts += 1;

            // If this was the last attempt, throw the error
            if (attempts >= 4) {
              console.error("Failed to fetch data after 4 attempts:", error);
              throw error;
            }
          }
        }
      };

      fetchData();
    }
  }, [props]);

  return (
    <React.Fragment>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={props.helpOpen[1] == "opts" ? "md" : "sm"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {props.helpOpen[1] == "help" && "Need Help?"}
          {props.helpOpen[1] == "opts" && `${props.helpOpen[2]} Options`}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {props.helpOpen[1] == "help" && (
            <Typography gutterBottom>
              If you need help using this app or some of the data doesn't look
              quite right, please email us at it@bialik.ca.
              <br />
              <br />
              Feature requests are also welcome! Let us know if there's
              something you'd like to see added.
              <br />
              <br />
              This app was made for Bialik by Josh Kellerstein (Bialik alum!)
            </Typography>
          )}
          {props.helpOpen[1] == "opts" && (
            <>
              <Typography gutterBottom variant="h6">
                Emails
              </Typography>
              <div className="dialogActionButtonsDiv">
                {!props.helpOpen[2].match(/JK|SK|1[A-Za-z]/) && (
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{ width: "100%" }}
                    onClick={() =>
                      window.open(
                        `mailto:?bcc=students_${props.helpOpen[2]}@students.bialik.ca`
                      )
                    }
                    className="dialogActionButtons"
                  >
                    Email All Students
                  </Button>
                )}
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ width: "100%" }}
                  onClick={() =>
                    window.open(
                      `mailto:?bcc=parents_${props.helpOpen[2]}@bialik.ca`
                    )
                  }
                  className="dialogActionButtons"
                >
                  Email All Parents
                </Button>
              </div>

              <Divider className="modalDivider" />

              <Typography gutterBottom variant="h6">
                Class Lists
              </Typography>
              {classListButtons.length == 0 ? (
                <Box sx={{ display: "flex", marginTop: "10px" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <div className="dialogActionButtonsDiv">{classListButtons}</div>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
